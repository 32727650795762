import { useSession } from "@store";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { AuthenticatedRequest } from "@types";
import axios from "axios";

export type AgentVoicesRequest = AuthenticatedRequest;
export type AgentVoice = { id: string; name: string; previewUrl: string };

export type AgentVoicesResponse = {
  "11labs": AgentVoice[];
};

export const getAgentVoices = async ({
  accessToken,
}: AgentVoicesRequest): Promise<AgentVoicesResponse> => {
  try {
    const response = await axios.get<AgentVoicesResponse>(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/utils/agent-voices`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch agent voices", error);
    throw error;
  }
};

export const useAgentVoices = (): UseQueryResult<AgentVoicesResponse> => {
  const { accessToken } = useSession();
  return useQuery({
    queryKey: ["useAgentVoices"],
    queryFn: () => getAgentVoices({ accessToken }),
  });
};
