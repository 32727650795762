import { ErrorInfo, PageHeader, Sidebar } from "@components";
import { useScreen } from "@hooks";
import { useSession, useUI } from "@store";
import { PendoWindow, TabItem } from "@types";
import { FC, ReactNode, memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

export type AuthenticatedLayoutProps = {
  actions?: ReactNode;
  children: ReactNode;
  icon?: string;
  description: string;
  showPhoneNumber?: boolean;
  tabs?: TabItem[];
  title: string;
};

export const AuthenticatedLayout: FC<AuthenticatedLayoutProps> = memo(
  ({ actions, children, description, icon, showPhoneNumber, title, tabs }) => {
    const location = useLocation();
    const { userIsAuthorized, user } = useSession();
    const { isWide } = useScreen();
    const { openSidebar } = useUI();

    useEffect(() => {
      isWide && openSidebar();
    }, [isWide, openSidebar]);

    useEffect(() => {
      try {
        const pendoWindow = window as PendoWindow;
        if (pendoWindow?.pendo?.initialize && user) {
          pendoWindow.pendo.initialize({
            visitor: {
              id: user?.id,
              email: user?.email,
              role: user?.role,
            },
            account: {
              id: user?.companyId,
            },
          });
        }
      } catch (error) {
        console.warn("Failed to initialize Pendo:", error);
      }
    }, [user]);

    return (
      <>
        <main className="xl:ml-72 h-screen overflow-auto flex flex-col">
          <PageHeader
            icon={icon}
            description={description}
            tabs={tabs}
            showPhoneNumber={showPhoneNumber}
            title={title}
          >
            {actions}
          </PageHeader>
          <div className="flex-1 overflow-auto p-10 bg-background">
            {userIsAuthorized(location.pathname) ? (
              children
            ) : (
              <ErrorInfo
                description="You are not authorized"
                subtitle="Access denied"
                title="Forbidden"
              />
            )}
          </div>
        </main>
        <Sidebar />
      </>
    );
  },
);
