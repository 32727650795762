import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";

try {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/api-stg\.sameday\.ai/,
        /^https:\/\/api\.sameday\.ai/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.VITE_PUBLIC_ENVIRONMENT,
    });
  }
} catch (error) {
  // Silently handle Sentry initialization errors
  console.warn("Sentry initialization failed:", error);
}

// Render the app
const rootElement = document.getElementById("app");
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(<App />);
} else {
  console.error("Failed to find the root element.");
}
