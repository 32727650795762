import Message from "./Message";
import { memo } from "react";

type TranscriptProps = {
  transcript: string;
  callType: string;
};

export const Transcript = memo(({ transcript, callType }: TranscriptProps) => {
  if (!transcript) return <div className="text-center">No transcript</div>;
  const messages = transcript.split("\n").filter(Boolean);
  return (
    <div className="flex flex-col gap-2">
      {messages.map((message, index) => {
        const speakerType = message.slice(0, 5) === "User:" ? "User" : "AI";
        const text =
          speakerType === "User"
            ? message.slice(6)
            : message.slice(3) === "AI:"
              ? message.slice(4)
              : message;
        return (
          <Message
            key={index}
            speaker={speakerType}
            text={text}
            callType={callType}
          />
        );
      })}
    </div>
  );
});
