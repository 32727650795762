import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface PromptSectionsRequest {
  accessToken: string;
}

export const getPromptSectionNames = async ({
  accessToken,
}: PromptSectionsRequest) => {
  try {
    const response = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/prompt-sections/section-names`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch prompt sections", error);
    throw error;
  }
};

export const useGetPromptSectionNames = () => {
  const { accessToken } = useSession();
  return useQuery({
    queryKey: ["useGetPromptSectionNames"],
    queryFn: () => getPromptSectionNames({ accessToken }),
  });
};
