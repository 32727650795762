import { memo } from "react";
import type { ReactNode } from "react";
import { DialogTitle as MatDialogTitle } from "@mui/material";

type DialogTitleProps = {
  children?: ReactNode;
};

export const DialogTitle = memo(({
  children
}: DialogTitleProps) => {
  return (
    <MatDialogTitle>
      {children}
    </MatDialogTitle>
  )
})