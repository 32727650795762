import {
  Spinner,
  Table as NextUITable,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Selection,
  SelectionMode,
  Button,
} from "@nextui-org/react";
import { PressEvent } from "@react-types/shared";
import { useSearchParams } from "react-router-dom";
import {
  ChangeEvent,
  Key,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
} from "react";

export type ColumnKey = string | number | symbol;

export type PageSize = "50" | "100" | "200";

export type TableAlign = "start" | "center" | "end";

export type TableSearchParams = {
  id?: string;
  page?: string;
  pageSize?: string;
};

export type TableColumn = {
  uid: string;
  name: string;
  align?: TableAlign;
};

export type TableItem = Record<ColumnKey, unknown>;

export type TableProps = {
  activeRowId?: string;
  columns: TableColumn[];
  disallowEmptySelection?: boolean;
  emptyContent: ReactNode;
  filters?: ReactNode;
  isFetching?: boolean;
  items: TableItem[];
  label: string;
  onRowAction?: (key: Key) => void;
  onSelectionChange?: (selection: Selection) => void;
  renderCell: (columnKey: ColumnKey, item: TableItem) => ReactNode;
  selectedKeys?: Selection;
  selectionBehaviour?: "replace" | "toggle";
  selectionMode: SelectionMode;
  topContent?: ReactNode;
  totalPages?: number;
};

export const Table = memo(
  ({
    activeRowId,
    columns,
    disallowEmptySelection,
    emptyContent,
    items,
    isFetching,
    label,
    onRowAction,
    onSelectionChange,
    renderCell,
    selectedKeys,
    selectionBehaviour = "replace",
    selectionMode,
    topContent,
    totalPages = 1,
  }: TableProps) => {
    const tableRef = useRef<HTMLTableElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get("page") || "1");

    useEffect(() => {
      tableRef.current?.parentElement?.setAttribute("style", "height: 400px");
    }, []);

    const onPageChangeHandler = useCallback(
      (e: PressEvent) => {
        const value = parseInt((e.target as HTMLButtonElement).value);
        if (!isNaN(value)) {
          searchParams.set("page", value.toString());
          setSearchParams(searchParams);
        }
      },
      [searchParams, setSearchParams],
    );

    const onPageSizeChangeHandler = useCallback(
      (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as PageSize;
        searchParams.set("pageSize", value);
        searchParams.set("page", "1"); // Reset to first page when changing page size
        setSearchParams(searchParams);
      },
      [searchParams, setSearchParams],
    );

    return (
      <NextUITable
        aria-label={label}
        bottomContent={
          totalPages > 1 && (
            <div className="flex w-full justify-between items-center text-textWeak text-sm px-6">
              <div>
                <label htmlFor="pageSize">Rows per page:</label>
                <select
                  id="pageSize"
                  className="bg-transparent"
                  onChange={onPageSizeChangeHandler}
                  value={searchParams.get("pageSize") || "50"}
                >
                  <option>50</option>
                  <option>100</option>
                  <option>200</option>
                </select>
              </div>
              <div className="flex flex-row items-center gap-4">
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <div className="flex flex-row gap-2">
                  <Button
                    value={currentPage - 1}
                    color="default"
                    variant="light"
                    onPress={onPageChangeHandler}
                    isDisabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Button
                    value={currentPage + 1}
                    color="default"
                    variant="light"
                    onPress={onPageChangeHandler}
                    isDisabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )
        }
        bottomContentPlacement="outside"
        classNames={{
          base: "grow",
          wrapper: "flex grow",
        }}
        color="primary"
        disallowEmptySelection={disallowEmptySelection}
        fullWidth
        isHeaderSticky
        onSelectionChange={onSelectionChange}
        onRowAction={onRowAction}
        ref={tableRef}
        selectedKeys={selectedKeys}
        selectionBehavior={selectionBehaviour}
        selectionMode={selectionMode}
        topContent={topContent}
        topContentPlacement="outside"
      >
        <TableHeader columns={columns}>
          {(column: TableColumn) => (
            <TableColumn
              key={column.uid}
              className={column.align ? `text-${column.align}` : ""}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={emptyContent}
          loadingState={isFetching ? "loading" : "idle"}
          items={items}
          loadingContent={<Spinner />}
        >
          {(item: TableItem) => (
            <TableRow
              className={
                activeRowId && activeRowId === item._id ? "bg-primary-100" : ""
              }
              key={item._id as string}
            >
              {(columnKey) => (
                <TableCell>{renderCell(columnKey, item)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </NextUITable>
    );
  },
);
