import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { AuthErrorBoundary } from "./AuthErrorBoundary";
import { RootErrorBoundary } from "./RootErrorBoundary";
import { authLoader } from "./authLoader";
import {
  getAgentLoader,
  getAvailableActionsLoader,
  getPlaybookLoader,
  getPromptSectionLoader,
  getPromptSectionsNamesLoader,
  getVoicesLoader,
} from "./loaders";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route
        path="/"
        errorElement={<RootErrorBoundary />}
        lazy={() =>
          import("../libs/Login/Login").then((module) => ({
            Component: module.default,
          }))
        }
      />
      <Route
        path="invitation"
        lazy={() =>
          import("../libs/Invitation/Invitation").then((module) => ({
            Component: module.default,
          }))
        }
      />
      <Route
        path="login"
        lazy={() =>
          import("../libs/Login/Login").then((module) => ({
            Component: module.default,
          }))
        }
      />
      <Route
        path="reset-password"
        lazy={() =>
          import("../libs/PasswordReset/PasswordReset").then((module) => ({
            Component: module.default,
          }))
        }
      />
      <Route errorElement={<AuthErrorBoundary />} loader={authLoader}>
        <Route
          path="agents"
          lazy={() =>
            import("@libs/Agents").then((module) => ({
              Component: module.Agents,
            }))
          }
        >
          <Route
            index
            lazy={() =>
              import("@libs/Agents").then((module) => ({
                Component: module.Agents,
              }))
            }
          />
          <Route path=":id">
            <Route
              index
              lazy={() =>
                import("@libs/Agents").then((module) => ({
                  Component: module.PersonalityContainer,
                }))
              }
            />
            <Route
              index
              path="personality"
              lazy={() =>
                import("@libs/Agents").then((module) => ({
                  Component: module.PersonalityContainer,
                }))
              }
            />
            <Route
              path="call-logic"
              lazy={() =>
                import("@libs/Agents").then((module) => ({
                  Component: module.CallLogicContainer,
                }))
              }
            />
            <Route
              path="prompting"
              lazy={() =>
                import("@libs/Agents").then((module) => ({
                  Component: module.PromptingContainer,
                }))
              }
            />
          </Route>
        </Route>
        <Route
          path="agents/new-full"
          lazy={() =>
            import("@pages").then((module) => ({
              Component: module.AgentEditor,
            }))
          }
          loader={getVoicesLoader}
        >
          <Route
            index
            lazy={() =>
              import("@libs/Agents").then((module) => ({
                Component: module.PersonalityContainer,
              }))
            }
          />
          <Route
            path="personality"
            lazy={() =>
              import("@libs/Agents").then((module) => ({
                Component: module.PersonalityContainer,
              }))
            }
          />
          <Route
            path="playbook"
            lazy={() =>
              import("@libs/Agents").then((module) => ({
                Component: module.PlaybookContainer,
              }))
            }
          />
        </Route>
        <Route
          path="agents/full/:id"
          loader={(params) => {
            return Promise.all([
              getAgentLoader(params),
              getVoicesLoader(),
            ]).then(([agent, voices]) => ({
              agent,
              voices,
            }));
          }}
          lazy={() =>
            import("@pages").then((module) => ({
              Component: module.AgentEditor,
            }))
          }
        >
          <Route
            index
            lazy={() =>
              import("@libs/Agents").then((module) => ({
                Component: module.PersonalityContainer,
              }))
            }
          />
          <Route
            path="personality"
            lazy={() =>
              import("@libs/Agents").then((module) => ({
                Component: module.PersonalityContainer,
              }))
            }
          />
          <Route
            path="playbook"
            lazy={() =>
              import("@libs/Agents").then((module) => ({
                Component: module.PlaybookContainer,
              }))
            }
          />
        </Route>
        <Route
          index
          path="company-selection"
          lazy={() =>
            import("../libs/CompanySelection/CompanySelection").then(
              (module) => ({
                Component: module.default,
              }),
            )
          }
        />
        <Route
          path="company"
          lazy={() =>
            import("@libs/Company").then((module) => ({
              Component: module.default,
            }))
          }
        >
          <Route
            index
            lazy={() =>
              import("@libs/Company").then((module) => ({
                Component: module.CompanyInfo,
              }))
            }
          />
          <Route
            path="hours"
            lazy={() =>
              import("@libs/Company").then((module) => ({
                Component: module.CompanyHours,
              }))
            }
          />
          <Route
            path="service-areas"
            lazy={() =>
              import("@libs/Company").then((module) => ({
                Component: module.CompanyServiceAreas,
              }))
            }
          />
          <Route
            path="priorities"
            lazy={() =>
              import("@libs/Company").then((module) => ({
                Component: module.CompanyPriorities,
              }))
            }
          />
          <Route
            path="transferring-numbers"
            lazy={() =>
              import("@libs/Company").then((module) => ({
                Component: module.TransferringNumbers,
              }))
            }
          />
          <Route
            path="text-messaging"
            lazy={() =>
              import("@libs/Company").then((module) => ({
                Component: module.TextMessaging,
              }))
            }
          />
        </Route>
        <Route
          path="conversations"
          lazy={() =>
            import("../libs/Conversations/Conversations").then((module) => ({
              Component: module.default,
            }))
          }
        />
        <Route
          path="integrations"
          lazy={() =>
            import("../libs/Integrations/Integrations").then((module) => ({
              Component: module.default,
            }))
          }
        >
          <Route
            index
            lazy={() =>
              import("../libs/Integrations/IntegrationsOverview").then(
                (module) => ({
                  Component: module.default,
                }),
              )
            }
          />
          <Route
            path="servicetitan"
            lazy={() =>
              import("../libs/Integrations/ServiceTitan").then((module) => ({
                Component: module.default,
              }))
            }
          />
          <Route
            path="fieldroutes"
            lazy={() =>
              import("../libs/Integrations/FieldRoutes").then((module) => ({
                Component: module.default,
              }))
            }
          />
        </Route>
        <Route
          path="playbooks/new"
          loader={() => {
            return Promise.all([
              getPromptSectionsNamesLoader(),
              getAvailableActionsLoader(),
            ]).then(([sectionNames, availableActions]) => ({
              sectionNames,
              availableActions,
            }));
          }}
          lazy={() =>
            import("../libs/NewPlaybook").then((module) => ({
              Component: module.NewPlaybook,
            }))
          }
        />
        <Route
          path="playbooks/:id"
          loader={(params) => {
            return Promise.all([
              getPlaybookLoader(params),
              getPromptSectionsNamesLoader(),
              getAvailableActionsLoader(),
            ]).then(([playbook, sectionNames, availableActions]) => ({
              playbook,
              sectionNames,
              availableActions,
            }));
          }}
          lazy={() =>
            import("../libs/NewPlaybook").then((module) => ({
              Component: module.NewPlaybook,
            }))
          }
        />
        <Route
          path="playbooks"
          lazy={() =>
            import("../libs/Playbooks").then((module) => ({
              Component: module.Playbooks,
            }))
          }
        >
          <Route
            index
            lazy={() =>
              import("../libs/Playbooks").then((module) => ({
                Component: module.PlaybooksContent,
              }))
            }
          />
          <Route
            path="prompt-sections"
            lazy={() =>
              import("../libs/Playbooks").then((module) => ({
                Component: module.PromptSections,
              }))
            }
          >
            <Route
              path=":sectionName"
              loader={getPromptSectionLoader}
              lazy={() =>
                import("../libs/Playbooks").then((module) => ({
                  Component: module.EditPromptSection,
                }))
              }
            />
            <Route
              path="new"
              lazy={() =>
                import("../libs/Playbooks").then((module) => ({
                  Component: module.NewPromptSection,
                }))
              }
            />
          </Route>
        </Route>
        <Route
          path="services"
          lazy={() =>
            import("../libs/Services/Services").then((module) => ({
              Component: module.default,
            }))
          }
        />
        <Route
          path="user-settings"
          lazy={() =>
            import("../libs/UserSettings/UserSettings").then((module) => ({
              Component: module.default,
            }))
          }
        />
        <Route
          errorElement={<RootErrorBoundary />}
          path="team"
          lazy={() =>
            import("../libs/Team/Team").then((module) => ({
              Component: module.default,
            }))
          }
        >
          <Route
            path="invite"
            lazy={() =>
              import("../libs/Team/components").then((module) => ({
                Component: module.InviteUsers,
              }))
            }
          />
        </Route>
      </Route>
    </Route>,
  ),
);
