import { memo } from "react";
import type { ReactNode } from "react";
import { Dialog as MatDialog } from "@mui/material";
import type { DialogProps as MatDialogProps } from "@mui/material";

type DialogProps = {
  children: ReactNode;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  open: boolean;
  onClose: () => void;
  scroll: "body" | "paper";
  title: string;
};

export const Dialog = memo(
  ({
    children,
    fullWidth = true,
    maxWidth='xs',
    open = false,
    onClose,
    scroll = "paper",
    title,
    ...dialogProps
  }: DialogProps & MatDialogProps) => {
    return (
      <MatDialog
        fullWidth
        maxWidth='xs'
        open={open}
        onClose={onClose}
        scroll={scroll}
        title={title}
        {...dialogProps}
      >
        {children}
      </MatDialog>
    )
  }
)