import { forwardRef, memo } from "react";
import type { ReactNode, InputHTMLAttributes } from "react";
import { Icon, IconifyIcon } from "@iconify/react";
import { InputAdornment, TextField } from "@mui/material";
import type { SxProps, TextFieldVariants } from "@mui/material";

type SelectProps = {
  children: ReactNode;
  color?: 'primary' | 'error';
  errorMessage? : string;
  helperText?: string;
  isInvalid?: boolean;
  label?: string;
  leftIcon?: string | IconifyIcon;
  size?: 'small';
  sx?: SxProps
  variant?: TextFieldVariants;
}

export const Select = memo(forwardRef<HTMLInputElement, SelectProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'color'>>(({
  children,
  color,
  errorMessage,
  helperText,
  isInvalid,
  label,
  leftIcon,
  size = 'small',
  sx,
  variant = 'standard',
  ...inputProps
}, ref) => {

  const leftIconProps = {
    ...(!!leftIcon && {
      startAdornment: (
        <InputAdornment position="start">
          <Icon icon={leftIcon}/>
        </InputAdornment>
      ),
    })
  }

  const subtitle = isInvalid ? errorMessage : helperText

  return (
    <TextField
      select
      color={color}
      error={isInvalid}
      helperText={subtitle}
      inputRef={ref}
      label={label}
      size={size}
      slotProps={{
        input: {
          ...leftIconProps
        }
      }}
      sx={sx}
      variant={variant}
      {...inputProps}
    >
      {children}
    </TextField>
  )
}))