import { store } from "@store";
import { queryClient } from "../queryClient";
import {
  getAgentVoices,
  getAvailableActions,
  getPromptsBySection,
  getPromptSectionNames,
} from "@services";
import { LoaderFunctionArgs } from "react-router-dom";
import { Playbook } from "@types";
import { Agent } from "@types";

const mockAgents: Agent[] = [
  {
    _id: "1",
    backgroundSound: "",
    customSettings: {},
    name: "Agent 1",
    firstMessage: "Hello, I'm Agent 1",
    maxDurationSeconds: 0,
    outboundConfig: {
      active: false,
      firstMessage: "",
      prompt: "",
    },
    active: false,
    personality: "Good Neighbor",
    promptConfig: {
      variables: {
        agentName: "Agent 1",
      },
      actions: [],
      objectives: [],
      recipe: [],
    },
    recordingEnabled: false,
    responseDelaySeconds: 0,
    totalCalls: 0,
    stats: {},
    voice: {
      name: "Voice 1",
      id: "SAz9YHcvj6GT2YYXdXww",
      previewURL: "",
      provider: "11labs",
      similarityBoost: 0,
      stability: 0,
      style: 0,
      useSpeakerBoost: false,
      optimizeStreamingLatency: 0,
    },
  },
  {
    _id: "2",
    backgroundSound: "",
    customSettings: {},
    name: "Agent 2",
    firstMessage: "Hello, I'm Agent 2",
    maxDurationSeconds: 0,
    outboundConfig: {
      active: false,
      firstMessage: "",
      prompt: "",
    },
    active: false,
    personality: "Good Neighbor",
    promptConfig: {
      variables: {
        agentName: "Agent 2",
      },
      actions: [],
      objectives: [],
      recipe: [],
    },
    recordingEnabled: false,
    responseDelaySeconds: 0,
    totalCalls: 0,
    stats: {},
    voice: {
      name: "Voice 2",
      id: "SAz9YHcvj6GT2YYXdXww",
      previewURL: "",
      provider: "11labs",
      similarityBoost: 0,
      stability: 0,
      style: 0,
      useSpeakerBoost: false,
      optimizeStreamingLatency: 0,
    },
  },
];

const mockPlaybooks: Playbook[] = [
  {
    id: "1",
    name: "Playbook 1",
    industry: "construction",
    description: "Playbook 1 description",
    successRate: 100,
    objectivePrompt: "Playbook 1 objective prompt",
    promptSections: [
      {
        section: "goal",
        identifier: "default",
        prompt: "Playbook 1 goal prompt",
      },
    ],
    actions: ["findSpot"],
  },
  {
    id: "2",
    name: "Playbook 2",
    industry: "construction",
    description: "Playbook 2 description",
    successRate: 80,
    objectivePrompt: "Playbook 2 objective prompt",
    promptSections: [],
    actions: [],
  },
  {
    id: "3",
    name: "Playbook 3",
    industry: "construction",
    description: "Playbook 3 description",
    successRate: 90,
    objectivePrompt: "Playbook 3 objective prompt",
    promptSections: [],
    actions: [],
  },
];

export const getPromptSectionLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const state = store.getState();
  const accessToken = state.session.accessToken;
  const user = state.session.user;
  const companyId = state.session?.companyId ?? user?.companyId ?? "";
  const data = await queryClient.fetchQuery({
    queryKey: ["useGetPromptsBySection", params.sectionName],
    queryFn: () =>
      getPromptsBySection({
        accessToken,
        section: params.sectionName || "",
        companyId,
      }),
  });
  return data;
};

export const getPromptSectionsNamesLoader = async () => {
  const state = store.getState();
  const accessToken = state.session.accessToken;
  const data = await queryClient.fetchQuery({
    queryKey: ["useGetPromptsSectionNames"],
    queryFn: () =>
      getPromptSectionNames({
        accessToken,
      }),
  });
  return data;
};

export const getAvailableActionsLoader = async () => {
  const state = store.getState();
  const accessToken = state.session.accessToken;
  const data = await queryClient.fetchQuery({
    queryKey: ["useGetAvailableActions"],
    queryFn: () => getAvailableActions({ accessToken }),
  });
  return data;
};

export const getPlaybookLoader = async ({ params }: LoaderFunctionArgs) => {
  // const state = store.getState();
  const id = params.id;
  // const accessToken = state.session.accessToken;
  const data = mockPlaybooks.find((playbook: Playbook) => playbook.id === id);
  return data;
};

export const getVoicesLoader = async () => {
  const state = store.getState();
  const accessToken = state.session.accessToken;
  const data = await queryClient.fetchQuery({
    queryKey: ["useAgentVoices"],
    queryFn: () => getAgentVoices({ accessToken }),
  });
  if (!data) {
    return [];
  } else {
    return data["11labs"];
  }
};

export const getAgentLoader = async ({ params }: LoaderFunctionArgs) => {
  // const state = store.getState();
  // const accessToken = state.session.accessToken;
  const data = mockAgents.find((agent) => agent._id === params.id);
  return data;
};
