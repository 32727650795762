type MessageProps = {
  speaker: string;
  text: string;
  callType: string;
};

export default function Message({ speaker, text, callType }: MessageProps) {
  const isAI = speaker === "AI";
  if (isAI) {
    return (
      <div
        className={`${callType === "phone" ? "bg-primary" : "bg-green-A200"} text-primary-foreground self-end ml-10 rounded-md p-2`}
      >
        {text}
      </div>
    );
  }
  return (
    <div>
      <div className="bg-default-200 text-default-foreground inline-flex mr-10 self-start rounded-md p-2">
        {text}
      </div>
    </div>
  );
}
