import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface PromptSectionIndentifierRequest {
  accessToken: string;
  section: string;
  companyId?: string;
}

export const getPromptsBySection = async ({
  accessToken,
  section,
  companyId,
}: PromptSectionIndentifierRequest) => {
  try {
    const response = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/prompt-sections/${section}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          companyId,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch prompt by section", error);
    throw error;
  }
};

export const useGetPromptsBySection = ({
  section,
  companyId,
}: {
  section: string;
  companyId?: string;
}) => {
  const { accessToken, user } = useSession();

  const companyIdQueryParam = companyId ?? user?.companyId ?? "";
  return useQuery({
    queryKey: ["useGetPromptsBySection", section],
    queryFn: () =>
      getPromptsBySection({
        accessToken,
        section,
        companyId: companyIdQueryParam,
      }),
  });
};
