import { Icon } from "@iconify/react/dist/iconify.js";
import { IconButton } from "@mui/material";
import { Box } from "@mui/material";
import { memo, ReactNode } from "react";

type DrawerHeaderProps = {
  children: ReactNode;
  handleCloseDrawer: () => void;
};

export const DrawerHeader = memo(
  ({ children, handleCloseDrawer }: DrawerHeaderProps) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 4,
          py: 2,
          borderBottom: "1px solid",
          borderColor: "grey.500",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {children}
        </Box>
        <IconButton aria-label="close-drawer" onClick={handleCloseDrawer}>
          <Icon icon="ic:outline-close" />
        </IconButton>
      </Box>
    );
  },
);
