import { Icon, IconifyIcon } from "@iconify/react";
import { InputAdornment, TextField } from "@mui/material";
import type { SxProps, TextFieldVariants } from "@mui/material";
import { forwardRef, InputHTMLAttributes, memo } from "react";

type TextInputProps = {
  color?: "primary" | "error";
  errorMessage?: string;
  helperText?: string;
  isInvalid?: boolean;
  label?: string;
  leftIcon?: string | IconifyIcon;
  multiline?: boolean;
  rows?: number;
  size?: "small";
  sx?: SxProps;
  variant?: TextFieldVariants;
};

export const TextInput = memo(
  forwardRef<
    HTMLInputElement,
    TextInputProps &
      Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "color">
  >(
    (
      {
        color,
        errorMessage,
        helperText,
        isInvalid,
        label,
        leftIcon,
        multiline,
        rows,
        size,
        sx,
        variant = "standard",
        ...inputProps
      },
      ref,
    ) => {
      const leftIconProps = {
        ...(!!leftIcon && {
          startAdornment: (
            <InputAdornment position="start">
              <Icon icon={leftIcon} />
            </InputAdornment>
          ),
        }),
      };

      const subtitle = isInvalid ? errorMessage : helperText;

      return (
        <TextField
          inputRef={ref}
          color={color}
          error={isInvalid}
          label={label}
          multiline={multiline}
          rows={rows}
          size={size}
          sx={sx}
          variant={variant}
          helperText={subtitle}
          slotProps={{
            input: {
              ...leftIconProps,
            },
          }}
          {...inputProps}
        />
      );
    },
  ),
);
