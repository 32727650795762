import { memo } from "react";
import type { ReactNode } from "react";
import { DialogActions as MatDialogActions } from "@mui/material";

type DialogActionsProps = {
  children?: ReactNode;
};

export const DialogActions = memo(({
  children
}: DialogActionsProps) => {
  return (
    <MatDialogActions>
      {children}
    </MatDialogActions>
  )
})