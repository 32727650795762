import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface AvailableActionsRequest {
  accessToken?: string;
}

export const getAvailableActions = async ({
  accessToken,
}: AvailableActionsRequest) => {
  try {
    const response = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/utils/available-actions`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch available actions", error);
    throw error;
  }
};

export const useGetAvailableActions = () => {
  const { accessToken } = useSession();
  return useQuery({
    queryKey: ["useGetAvailableActions"],
    queryFn: () => getAvailableActions({ accessToken }),
  });
};
