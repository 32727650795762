import { memo, ReactNode } from "react";
import { Box, Drawer as MuiDrawer } from "@mui/material";
import { DrawerHeader } from "./DrawerHeader";
import { DrawerFooter } from "./DrawerFooter";

type DrawerProps = {
  anchor: "left" | "right" | "top" | "bottom";
  children: ReactNode;
  footer: ReactNode;
  form?: boolean;
  header: ReactNode;
  loading?: boolean;
  skeleton?: ReactNode;
  open: boolean;
  onClose: () => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  width?: number;
};

export const Drawer = memo(
  ({
    width,
    children,
    footer,
    header,
    loading,
    skeleton,
    open,
    onClose,
    onSubmit,
    anchor,
  }: DrawerProps) => {
    return (
      <MuiDrawer
        sx={{
          zIndex: 12,
          "& .MuiDrawer-paper": {
            backgroundColor: "background.default",
            borderRadius: 0,
            borderColor: "grey.500",
            width: width,
            position: "fixed",
          },
        }}
        open={open}
        onClose={onClose}
        anchor={anchor}
      >
        <form
          onSubmit={onSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DrawerHeader handleCloseDrawer={onClose}>{header}</DrawerHeader>
          <Box sx={{ flex: 1, overflow: "auto", px: 4, py: 3 }}>
            {loading ? skeleton : children}
          </Box>
          <DrawerFooter>{footer}</DrawerFooter>
        </form>
      </MuiDrawer>
    );
  },
);
