import { memo } from "react";
import type { ReactNode } from "react";
import { DialogContent as MatDialogContent } from "@mui/material";

type DialogContentProps = {
  children?: ReactNode;
};

export const DialogContent = memo(({
  children
}: DialogContentProps) => {
  return (
    <MatDialogContent>
      {children}
    </MatDialogContent>
  )
})