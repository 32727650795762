import { Box } from "@mui/material";

import { memo, ReactNode } from "react";

type DrawerFooterProps = {
  children: ReactNode;
};

export const DrawerFooter = memo(({ children }: DrawerFooterProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        px: 4,
        py: 3,
        borderTop: "1px solid",
        borderColor: "grey.500",
      }}
    >
      {children}
    </Box>
  );
});
