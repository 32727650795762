import { memo } from "react";
import type { ReactNode, ButtonHTMLAttributes } from "react";
import MatButton from "@mui/lab/LoadingButton";

type ButtonProps = {
  children?: ReactNode;
  color?: "primary" | "secondary" | "error" | "default";
  isDisabled?: boolean;
  isLoading?: boolean;
  size?: "small" | "medium" | "large";
  startIcon?: ReactNode;
  variant?: "text" | "outlined" | "contained";
};

export const Button = memo(
  ({
    children,
    color,
    isDisabled,
    isLoading,
    size,
    startIcon,
    variant = "contained",
    ...restProps // includes all the normal HTML button props
  }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
      <MatButton
        color={color}
        disabled={isDisabled}
        loading={isLoading}
        size={size}
        startIcon={startIcon}
        variant={variant}
        {...restProps}
      >
        {children}
      </MatButton>
    );
  },
);
