import { Icon } from "@iconify/react/dist/iconify.js";
import { Button } from "@nextui-org/react";
import clsx from "clsx";
import {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export type PopUpDrawerProps = {
  actions?: ReactNode;
  children: ReactNode;
  icon?: string;
  isOpen: boolean;
  onClose: VoidFunction;
  subtitle?: string;
  title?: string;
};

export const PopUpDrawer: FC<PopUpDrawerProps> = memo(
  ({ children, icon, isOpen, onClose, subtitle, title }) => {
    const [hidden, setHidden] = useState(!isOpen);
    const keyupHandler = useCallback(
      (event: KeyboardEvent) => isOpen && event.code === "Escape" && onClose(),
      [isOpen, onClose],
    );
    useEffect(() => {
      isOpen && ref.current?.focus();
      isOpen && setHidden(false);
      isOpen
        ? window.addEventListener("keyup", keyupHandler)
        : window.removeEventListener("keyup", keyupHandler);
      return () => window.removeEventListener("keyup", keyupHandler);
    }, [isOpen, keyupHandler, onClose]);
    const ref = useRef<HTMLDivElement>(null);
    const transitionEndHandler = useCallback(
      () => setHidden(!isOpen),
      [isOpen],
    );
    return (
      <div
        className={clsx("fixed h-screen left-0 top-0 w-screen z-50", {
          "top-0": !hidden,
          "top-full": hidden,
        })}
      >
        <div
          className={clsx(
            "absolute bg-overlay/50 flex h-screen left-0 top-0 transition-opacity duration-300 w-screen",
            {
              "opacity-100": isOpen,
              "opacity-0": !isOpen,
            },
          )}
          onClick={onClose}
          onTransitionEnd={transitionEndHandler}
          tabIndex={-1}
        />
        <section
          className={clsx(
            "bg-background-50 fixed flex flex-col h-[calc(100vh-7rem)] px-10 py-6 rounded-t-3xl transition-all duration-300 w-screen",
            {
              "top-28": isOpen,
              "top-full": !isOpen,
            },
          )}
        >
          <header className="flex justify-between w-full mb-8">
            <div className="flex flex-col gap-2">
              <div className="items-center flex gap-2">
                {icon && <Icon height={32} icon={icon} />}
                <h2 className="capitalize text-4xl leading-10 font-medium">
                  {title}
                </h2>
              </div>
              <h3 className="text-small">{subtitle}</h3>
            </div>
            <Button
              className="rounded-full"
              isIconOnly
              onClick={onClose}
              variant="light"
            >
              <Icon icon="fluent:dismiss-24-regular" />
            </Button>
          </header>
          <div className="focus-trap outline-none" ref={ref} tabIndex={0} />
          <div className="overflow-y-auto">{children}</div>
        </section>
      </div>
    );
  },
);
